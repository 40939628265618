import styled from 'styled-components';

import { tablet } from 'utils/media';

export const FormContainer = styled.div`
  max-width: 840px;
  margin: 0 auto;
`;

export const Fields = styled.div`
  display: grid;
  grid-gap: 20px;

  ${tablet} {
    grid-template-columns: 1fr 1fr;

    > *[for='phoneNumber'],
    > *[for='message'] {
      grid-column: span 2;
    }
  }
`;

export const ButtonContainer = styled.div`
  text-align: center;
`;
