import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: var(--space-l) 0;
`;

export const TextWrapper = styled.div`
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
`;
