import styled from 'styled-components';

import theme from 'utils/theme';

export const ErrorMessage = styled.span`
  font-size: var(--step--1);
  line-height: 24px;
  color: ${theme.colors.errorRed};
  margin-left: 16px;
`;
