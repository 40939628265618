import styled, { keyframes } from 'styled-components';

import theme from 'utils/theme';

import { LoaderDiv } from '../loader/styles';

const LoaderFadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 0.5;
  }
`;

interface VideoFrameProps {
  activeAndLoading?: boolean;
}

export const VideoFrame = styled.div<VideoFrameProps>`
  position: absolute;
  top: -2px;
  left: 0;
  right: 0;
  bottom: -2px;
  overflow: hidden;
  font-size: 0;
  line-height: 0;

  ${LoaderDiv} {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: transparent;
    filter: brightness(5);
    opacity: 0;
    max-width: 30%;
    animation: ${({ activeAndLoading }) =>
        activeAndLoading ? LoaderFadeIn : null}
      ${theme.transitionSpeeds.slow}s 1s forwards;
  }
`;

export const VideoElement = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
