import styled from 'styled-components';

import { Heading4 } from 'components/common/text';
import { mobile, tablet } from 'utils/media';
import theme from 'utils/theme';

import { ArticleProps } from './types';

export const Grid = styled.div({
  [tablet]: {
    display: 'flex',
    padding: '0 20px',
  },
});

export const ArticleImage = styled.div({});

export const ArticleInfo = styled.div({
  marginTop: 30,
});

export const CategoryLabel = styled.p({
  color: theme.colors.darkGreen,
  fontSize: 'var(--step-0)',
  letterSpacing: '-0.02em',
  fontWeight: 'bold',
  margin: 0,
});

export const ArticleLabel = styled(Heading4)({
  'p + &': {
    marginTop: 8,
  },
  margin: 0,
});

export const ArticleLede = styled.p({
  color: 'rgba(0, 0, 0, 0.55)',
});

export const DateLabel = styled.p({
  color: 'rgba(0, 0, 0, 0.55)',
  margin: '16px 0 0',
});

export const ArticleEl = styled.article<ArticleProps>((props) => ({
  flex: 1,
  padding: 20,
  a: {
    color: 'inherit',
    ...(props.size === 'medium'
      ? {
          [ArticleLabel]: {
            fontSize: 'var(--step-2)',
          },
          [mobile]: {
            display: 'flex',
            borderBottom: '1px solid rgba(0,0,0,0.1)',
            paddingBottom: 30,
            [ArticleImage]: {
              width: 150,
              flexShrink: 0,
              order: 2,
              marginLeft: 20,
            },
            [ArticleInfo]: {
              marginTop: 0,
              flex: 1,
            },
            [CategoryLabel]: {
              fontSize: 'var(--step--2)',
              lineHeight: 1.3,
            },
            [ArticleLabel]: {
              fontSize: 'var(--step--1)',
              lineHeight: 1.3,
            },
            [ArticleLede]: {
              display: 'none',
            },
            [DateLabel]: {
              fontSize: 'var(--step--2)',
              lineHeight: 1.3,
            },
          },
        }
      : {}),
    ...(props.size === 'large'
      ? {
          [mobile]: {
            [ArticleInfo]: {
              marginTop: 16,
            },
            [ArticleLabel]: {
              fontSize: 'var(--step-2)',
            },
            [DateLabel]: {
              marginTop: 8,
            },
          },
        }
      : {}),
  },
  '&:last-child': {
    a: {
      borderBottomWidth: 0,
      paddingBottom: 0,
    },
  },
}));
