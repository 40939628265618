import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from 'react';

import { Mute, Unmute } from 'components/common/icons';
import Loader from 'components/common/loader';
import { VideoFrame, VideoElement } from 'components/common/video-frame/style';
import { isClientSide } from 'utils/helpers';

import { MuteControl } from './styles';
import { SocialCarouselVideoProps } from './types';

function SocialCarouselVideo({
  video,
  aspectRatio,
  active,
  altText,
  hasSound = false,
  isMuted,
  setMute,
  onEnded,
}: SocialCarouselVideoProps) {
  const urlEncodedId = encodeURI(video.public_id);
  const baseWidth = 1000;
  const posterSrc = `https://res.cloudinary.com/sproutl/video/upload/c_fill,h_${
    baseWidth * aspectRatio
  },so_0,w_${baseWidth}/v1/${urlEncodedId}.webp`;

  const videoSource = useMemo(() => {
    if (!isClientSide()) return null;

    const multiplier = window.matchMedia('(min-width: 1000px)').matches ? 2 : 1;
    const width = baseWidth * multiplier;
    const height = width * aspectRatio;

    return {
      formats: [
        {
          src: `https://res.cloudinary.com/sproutl/video/upload/q_auto,f_webm,w_${width},h_${height},c_fill/${urlEncodedId}.webm`,
          type: 'video/webm',
        },
        {
          src: `https://res.cloudinary.com/sproutl/video/upload/q_auto,f_mp4,vc_h265,w_${width},h_${height},c_fill/${urlEncodedId}.mp4`,
          type: 'video/mp4; codecs=hvc1',
        },
        {
          src: `https://res.cloudinary.com/sproutl/video/upload/q_auto,f_mp4,vc_h264,w_${width},h_${height},c_fill/${urlEncodedId}.mp4`,
          type: 'video/mp4',
        },
      ],
    };
  }, [baseWidth, aspectRatio, urlEncodedId]);

  const ref = useRef<HTMLVideoElement>(null);

  const [hasLoaded, setLoadedState] = useState(false);

  /**
   * Auto play/pause when the slide comes into view
   */
  useEffect(() => {
    if (!ref.current) return;

    ref.current[active ? 'play' : 'pause']();
  }, [ref, active]);

  /**
   * Pass out the onEnded event, and reset the video time
   */
  const internalOnEnded = useCallback(
    (e) => {
      onEnded && onEnded(e);

      setTimeout(() => {
        if (ref.current) {
          ref.current.currentTime = 0;
        }
      }, 500);
    },
    [ref, onEnded],
  );

  return (
    <>
      <VideoFrame activeAndLoading={active && !hasLoaded}>
        <VideoElement
          autoPlay
          muted={isMuted}
          playsInline
          poster={posterSrc}
          ref={ref}
          title={altText}
          onEnded={internalOnEnded}
          onLoadedData={() => setLoadedState(true)}
        >
          {active && videoSource
            ? videoSource.formats.map((format) => (
                <source key={format.src} src={format.src} type={format.type} />
              ))
            : null}
        </VideoElement>

        {hasLoaded ? null : <Loader />}

        {hasSound && (
          <MuteControl onClick={() => setMute(!isMuted)}>
            {isMuted ? (
              <Mute width={20} height={20} />
            ) : (
              <Unmute width={20} height={20} />
            )}
          </MuteControl>
        )}
      </VideoFrame>
    </>
  );
}

export default SocialCarouselVideo;
