import React, { useState, useEffect, useCallback } from 'react';

import Button from 'components/common/button';
import Container from 'components/common/container';
import Section from 'components/common/section';
import AddToNotification from 'components/product/add-to-basket-notification';
import ProductGrid from 'components/product/product-grid';
import { useProductSetAddToBag } from 'pages/explore/hooks';
import { RichTitleWrapper } from 'pages/explore/styles';
import { mobile, tablet } from 'utils/media';
import { PortableText } from 'utils/sanity';

import { TextWrapper, ButtonContainer } from './styles';
import { IProductsSetProps } from './types';

const ProductsSet = ({
  allowBuyAll,
  products,
  text,
  richTitle,
}: IProductsSetProps) => {
  const { addToBag, addedToBag } = useProductSetAddToBag();

  const [showBuyAllBtn, setShowBuyAllBtn] = useState(allowBuyAll);

  useEffect(() => {
    if (allowBuyAll) {
      const allProductsInStock = products.reduce(
        (acc, { isOnStock }) => (acc ? !!isOnStock : false),
        true,
      );

      setShowBuyAllBtn(allProductsInStock);
    }
  }, [allowBuyAll, products]);

  const onAddToBag = useCallback(() => {
    if (addToBag) {
      addToBag(products);
    }
  }, [addToBag, products]);

  return (
    <Section>
      <Container>
        {richTitle ? (
          <RichTitleWrapper>
            <PortableText blocks={richTitle} />
          </RichTitleWrapper>
        ) : null}
        <ProductGrid
          skeletonOutOfStockProducts
          products={products}
          isQuickBuyEnabled
          columns={{
            [mobile]: 2,
            [tablet]: 4,
          }}
        />

        {text && (
          <TextWrapper>
            <PortableText blocks={text} />
          </TextWrapper>
        )}

        {showBuyAllBtn && addToBag && (
          <ButtonContainer>
            <Button onClick={onAddToBag}>Buy the Look</Button>
          </ButtonContainer>
        )}
        {addedToBag ? (
          <AddToNotification
            isOpen
            productDetails={addedToBag.items}
            key={addedToBag.updatedAt.toString()}
          />
        ) : null}
      </Container>
    </Section>
  );
};

export default ProductsSet;
