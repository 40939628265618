import styled from 'styled-components';

export const PanelWrapper = styled.div({
  marginTop: 80,
});

export const RichTitleWrapper = styled.div`
  margin-bottom: 60px;
  text-align: center;

  // TODO: better Sanity structure so we don't have to do this
  // workaround for a WYSYWIG used as a title block
  h3 + * {
    margin-top: -20px;
  }
`;
