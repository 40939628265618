import React, { FC, useRef, useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import Button from 'components/common/button';
import ImageComponent from 'components/common/image';
import SiteLink from 'components/common/site-link';
import {
  swapPhoto,
  mousePosition,
} from 'components/product/product-item/helpers';
import {
  QuickBuyWrapper,
  VariantPricingAndQuickBuyWrapper,
} from 'components/product/product-item/styles';
import { getProductUrl } from 'utils/commercetools/product';
import formatPrice from 'utils/formatPrice';
import { mobile } from 'utils/media';

import {
  StyledProductCarouselItem,
  StyledResponsiveBox,
  Label,
  Price,
} from './styles';
import { IProductCarouselItemProps } from './types';

export const ProductCarouselItem: FC<IProductCarouselItemProps> = ({
  product,
  enableQuickBuy,
  toggleQuickBuyModal,
  setQuickBuyModalContent,
}) => {
  const { name, price, photos } = product;

  const [displayedPhoto, setDisplayedPhoto] =
    useState<Sproutl.CloudinaryImageAsset | null>(photos?.[0] ?? null);

  const hasPreloadedSecondImage = useRef<boolean>(false);

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  useEffect(() => {
    // Preload the second image on entrance to the viewport
    // in the same fashion as webP to prevent flicker in loading the image on hover.
    if (photos?.[1] && !hasPreloadedSecondImage.current) {
      new Image().src = photos[1].secure_url;
      hasPreloadedSecondImage.current = true;
    }
  }, [photos, inView, hasPreloadedSecondImage]);

  return (
    <StyledProductCarouselItem
      size="large"
      data-testid="productCarouselItem"
      enableQuickBuy={enableQuickBuy}
      ref={ref}
      onMouseEnter={() =>
        swapPhoto({ photos, mousePos: mousePosition.In, setDisplayedPhoto })
      }
      onMouseLeave={() =>
        swapPhoto({ photos, mousePos: mousePosition.Out, setDisplayedPhoto })
      }
    >
      <SiteLink href={getProductUrl(product)} title={name}>
        <StyledResponsiveBox aspectRatio={1}>
          {displayedPhoto && (
            <ImageComponent
              loader="cloudinary"
              src={displayedPhoto}
              aspectRatio={1}
              sizes={`${mobile} 150px, 290px`}
              alt={name}
            />
          )}
        </StyledResponsiveBox>
        {!enableQuickBuy && <Label>{name}</Label>}
      </SiteLink>
      {enableQuickBuy && (
        <VariantPricingAndQuickBuyWrapper displayQuickBuyButton>
          <Label>{name}</Label>
          {price && <Price>{formatPrice(price)}</Price>}
          <QuickBuyWrapper>
            <Button
              variant="tertiaryFilled"
              width="full"
              onClick={() => {
                if (toggleQuickBuyModal && setQuickBuyModalContent) {
                  setQuickBuyModalContent(product);
                  toggleQuickBuyModal(true);
                }
              }}
            >
              Quick Buy
            </Button>
          </QuickBuyWrapper>
        </VariantPricingAndQuickBuyWrapper>
      )}
    </StyledProductCarouselItem>
  );
};

export default ProductCarouselItem;
