import styled from 'styled-components';

import Panel from 'components/common/panel';
import { Heading4 } from 'components/common/text';
import theme from 'utils/theme';

export const Container = styled.div`
  padding: 0 20px;
  width: 490px;
  max-width: 100%;
  margin: 0 auto;
`;

export const PanelContent = styled.div`
  padding: 40px 35px;
`;

export const ButtonContainer = styled.div`
  text-align: center;
`;

export const Fields = styled.div`
  > * + * {
    margin-top: 22px;
  }
`;

export const StyledHeading4 = styled(Heading4)`
  color: ${theme.colors.purple};
`;

export const ImageWrapper = styled.div`
  position: relative;
`;

export const StyledPanel = styled(Panel)`
  box-shadow: ${theme.boxShadows.light};
`;
