import styled, { css } from 'styled-components';

import { sizeStyles, ArrowButton } from 'components/common/carousel/styles';
import { CarouselItemProps } from 'components/common/carousel/types';
import ResponsiveBox from 'components/common/responsive-box';
import { HeadingWrapper } from 'components/common/text';
import { QuickBuyWrapper } from 'components/product/product-item/styles';
import { desktop, tablet, mobile, largeDesktop } from 'utils/media';
import theme from 'utils/theme';

export const StyledResponsiveBox = styled(ResponsiveBox)`
  background-color: ${theme.colors.white};
  border-radius: 27%;
`;

export const HeaderContainer = styled.header`
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: var(--site-gutter);

  ${HeadingWrapper} {
    padding-left: 0;
    margin-bottom: 0;
  }

  & > * + * {
    margin-top: var(--space-3xs-2xs);
  }

  > p {
    font-size: var(--step-1);
  }

  ${tablet} {
    padding-left: 40px;
    padding-right: 40px;
  }
`;

export const TitleSpacer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const Label = styled.div`
  margin-top: 16px;
  font-weight: ${theme.weights.bold};
  line-height: 1.5;
  color: ${theme.colors.black};

  ${mobile} {
    font-size: var(--step--1);
  }
`;

export const Price = styled.div`
  color: ${theme.colors.darkGreen};
  font-weight: ${theme.weights.bold};
  margin-top: var(--space-4xs);
  margin-bottom: var(--space-s);

  @media (hover: hover) {
    margin-bottom: 0;
  }
`;

interface TabLinkProps {
  active: boolean;
}

export const TabLink = styled.button<TabLinkProps>`
  ${(props) => props.active && 'display: none;'};
  appearance: none;
  border: 0;
  padding: 0;
  font-size: var(--step-0);
  margin: 0 0 20px 0;
  background: transparent;
  font-weight: bold;
  color: ${theme.colors.black};
  opacity: ${(props) => (props.active ? 1 : 0.6)};
  text-decoration: ${(props) => (props.active ? 'underline' : 'none')};
  cursor: pointer;

  ${tablet} {
    display: unset;
  }

  &:focus,
  &:hover {
    outline: none;
    opacity: ${(props) => (props.active ? 1 : 0.7)};
  }

  & + & {
    margin-left: var(--space-xs-s);
  }
`;

export const TabsRow = styled.div``;

const hoverStylesWithQuickBuyEnabled = `
  &:hover {
    ${Price}, ${Label} {
      opacity: 0;
    }
    ${QuickBuyWrapper} {
      opacity: 1;
    }
  }`;

const mobileSpecificChildStyles = `
  &:first-child {
    padding-left: 20px;
  }
  &:last-child {
    padding-right: 20px;
  }
`;

interface IStyledProductCarouselItem extends CarouselItemProps {
  enableQuickBuy?: boolean;
}

export const StyledProductCarouselItem = styled.div<IStyledProductCarouselItem>`
  box-sizing: content-box;
  flex-shrink: 0;
  padding: 0 calc(var(--site-gutter) / 2);
  flex: 1;
  display: flex;
  flex-direction: column;
  scroll-snap-align: center;

  ${desktop} {
    ${({ enableQuickBuy }) =>
      enableQuickBuy ? hoverStylesWithQuickBuyEnabled : ''}
  }

  ${({ inline }) =>
    inline
      ? css`
          ${mobileSpecificChildStyles}
          ${tablet} {
            &:first-child {
              padding-left: 0;
            }
            &:last-child {
              padding-right: 0;
            }
          }
        `
      : css`
          ${mobileSpecificChildStyles}
          ${tablet} {
            &:first-child {
              padding-left: 40px;
            }
            &:last-child {
              padding-right: 40px;
            }
          }
        `}

  ${({ size = 'normal' }) => sizeStyles[size]}
`;

interface IProductCarouselWrapper {
  hasImage: boolean;
}

export const ProductCarouselWrapper = styled.div<IProductCarouselWrapper>`
  ${ArrowButton} {
    ${tablet} {
      top: calc(clamp(150px, 25vw, 290px) / 2);
    }
  }

  ${({ hasImage }) =>
    hasImage
      ? css`
          --gutter-size: 20px;

          display: grid;
          grid-gap: var(--gutter-size);
          grid-template-columns: 100%;
          align-items: start;

          > figure {
            margin-left: var(--gutter-size);
            margin-right: var(--gutter-size);
            width: calc(100% - 40px);
            max-width: 500px;
            justify-self: center;

            img {
              border-radius: var(--space-2xl-4xl);
              width: 100%;
            }
          }

          ${desktop} {
            --gutter-size: 40px;
            --image-size: 35%;

            grid-template-columns: var(--image-size) calc(
                100vw - var(--image-size) - var(--gutter-size)
              );

            > figure {
              margin-right: 0;
            }
          }

          ${largeDesktop} {
            --image-size: calc(463px + var(--gutter-size));
            align-items: center;
          }

          @media (min-width: 1920px) {
            grid-template-columns: var(--image-size) calc(
                1920px - var(--image-size) - var(--gutter-size)
              );
          }
        `
      : ''}
`;
