import React from 'react';

import BackgroundVideo from 'components/common/background-video';
import Button from 'components/common/button';
import Container from 'components/common/container';
import Image from 'components/common/image';
import Section from 'components/common/section';
import SiteLink from 'components/common/site-link';
import { Heading2 } from 'components/common/text';
import { mobile, tablet, desktop } from 'utils/media';

import { isOverlay } from './helpers';
import {
  TwoColWrapper,
  Banner,
  TextSection,
  ButtonWrapper,
  LabelWrapper,
  StyledHeading4,
  StyledMaskedIcon,
  OverlayResponsiveBox,
  DesktopLargerHeading2,
} from './styles';
import { BillboardProps } from './types';

function Billboard({
  colorScheme = {
    background: 'pineGreen',
    text: 'coleusGreen',
  },
  title,
  body,
  label,
  cta,
  banner,
  bannerAlt = '',
  bannerAlignment,
  contentWidth = '50%',
  darkenOverlay,
  video,
  titleSize,
  index,
}: BillboardProps) {
  const preloadVideoPoster = index === 0;

  return (
    <Container noGutter noPadding>
      {isOverlay(bannerAlignment) ? (
        <OverlayResponsiveBox
          aspectRatio={{ [mobile]: 2 / 3, [tablet]: 1 / 3 }}
          textColor={colorScheme?.text}
        >
          {banner || video ? (
            <Banner alignment={bannerAlignment}>
              {video ? (
                <BackgroundVideo
                  video={video}
                  preloadPoster={preloadVideoPoster}
                  breakpoints={{
                    [mobile]: { width: 750, height: 488 },
                    [tablet]: { width: 1024, height: 690 },
                    [desktop]: { width: 1920, height: 1288 },
                  }}
                />
              ) : banner ? (
                <Image
                  src={banner}
                  aspectRatio={{
                    [mobile]: 16 / 25,
                    [tablet]: 1 / 3,
                  }}
                  sizes="100vw"
                  alt={bannerAlt}
                />
              ) : null}
            </Banner>
          ) : null}
          <TextSection
            alignment={bannerAlignment}
            darkenOverlay={darkenOverlay}
            contentWidth={contentWidth}
          >
            <div>
              {label ? (
                <LabelWrapper>
                  <StyledHeading4 as="p">
                    {label.icon ? (
                      <StyledMaskedIcon asset={label.icon} />
                    ) : null}
                    {label.copy}
                  </StyledHeading4>
                </LabelWrapper>
              ) : null}
              {!title ? null : titleSize === 'h1' ? (
                <DesktopLargerHeading2 inline>{title}</DesktopLargerHeading2>
              ) : (
                <Heading2 inline>{title}</Heading2>
              )}
              {body ? <p>{body}</p> : null}
              {cta && cta.title && cta.url ? (
                <ButtonWrapper>
                  <Button
                    as="span"
                    variant="secondary"
                    colorScheme={colorScheme?.text}
                  >
                    {cta.title}
                  </Button>
                </ButtonWrapper>
              ) : null}
            </div>
          </TextSection>
        </OverlayResponsiveBox>
      ) : (
        <TwoColWrapper
          alignment={bannerAlignment}
          backgroundColor={colorScheme?.background}
          textColor={colorScheme?.text}
        >
          {banner || video ? (
            <Banner alignment={bannerAlignment}>
              {video ? (
                <BackgroundVideo
                  video={video}
                  preloadPoster={preloadVideoPoster}
                  breakpoints={{
                    [mobile]: { width: 750, height: 488 },
                    [tablet]: { width: 1024, height: 690 },
                    [desktop]: { width: 1920, height: 1288 },
                  }}
                />
              ) : banner ? (
                <Image
                  src={banner}
                  aspectRatio={{ [mobile]: 16 / 25, [tablet]: 2 / 3 }}
                  sizes={`${mobile} 100vw, 50vw`}
                  alt={bannerAlt}
                />
              ) : null}
            </Banner>
          ) : null}
          <TextSection
            alignment={bannerAlignment}
            darkenOverlay={darkenOverlay}
            contentWidth={contentWidth}
          >
            <div>
              {label ? (
                <LabelWrapper>
                  <StyledHeading4 as="p">
                    {label.icon ? (
                      <StyledMaskedIcon asset={label.icon} />
                    ) : null}
                    {label.copy}
                  </StyledHeading4>
                </LabelWrapper>
              ) : null}
              {!title ? null : titleSize === 'h1' ? (
                <DesktopLargerHeading2 inline>{title}</DesktopLargerHeading2>
              ) : (
                <Heading2 inline>{title}</Heading2>
              )}
              {body ? <p>{body}</p> : null}
              {cta && cta.title && cta.url ? (
                <ButtonWrapper>
                  <Button
                    as="span"
                    variant="secondary"
                    colorScheme={colorScheme?.text}
                  >
                    {cta.title}
                  </Button>
                </ButtonWrapper>
              ) : null}
            </div>
          </TextSection>
        </TwoColWrapper>
      )}
    </Container>
  );
}

export default function BillboardWrapper(billboardProps: BillboardProps) {
  const { cta } = billboardProps;

  return (
    <Section padding="none" fullBleed>
      {cta && cta.title && cta.url ? (
        <SiteLink href={cta.url}>
          <Billboard {...billboardProps} />
        </SiteLink>
      ) : (
        <Billboard {...billboardProps} />
      )}
    </Section>
  );
}
