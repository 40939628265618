import styled from 'styled-components';

import ResponsiveBox from 'components/common/responsive-box';
import Section from 'components/common/section';
import { desktop, tablet } from 'utils/media';
import theme from 'utils/theme';

export const ShortcutGridSection = styled(Section)`
  padding: 40px 0 30px;

  ${tablet} {
    padding: 60px 0;
  }
`;

export const StyledResponsiveBox = styled(ResponsiveBox)({
  backgroundColor: theme.colors.offWhite,
  zIndex: 0,
  borderRadius: 24,

  [desktop]: {
    borderRadius: 32,
  },
});

export const Label = styled.div`
  font-family: ${theme.fonts.heading};
  margin-top: 16px;
  font-weight: ${theme.weights.headingBold};
  font-size: var(--step-2);
`;

export const MaskedIconWrapper = styled.div({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1,
  color: theme.colors.white,
  backgroundColor: theme.colors.iconUnderlay,
});

export const StyledLink = styled.a({
  color: theme.colors.black,
  textAlign: 'center',

  '&:hover, &:focus': {
    color: theme.colors.purple,
  },
});
