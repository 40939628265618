import styled from 'styled-components';

import { mobile, tablet, desktop } from 'utils/media';
import theme from 'utils/theme';

interface BackgroundProps {
  colorScheme?: {
    background?: string;
    text?: string;
  };
}

export const Background = styled.div<BackgroundProps>`
  background-color: ${(props) =>
    theme.colors[props.colorScheme?.background || 'irisPurple']};
  color: ${(props) => theme.colors[props.colorScheme?.text || 'white']};
  ${(props) =>
    (props.colorScheme?.text || 'white') === 'white'
      ? '-webkit-font-smoothing: antialiased;'
      : ''}
  font-size: var(--step-2);
`;

export const Title = styled.div`
  font-weight: ${theme.weights.bold};

  ${mobile} {
    border-right: 1px solid currentColor;
    padding-right: 20px;
    padding-bottom: 40px;
  }

  ${tablet} {
    margin-bottom: 40px;
  }

  ${desktop} {
    h1,
    h2,
    h3,
    h4,
    p,
    blockquote {
      max-width: 50%;
    }
  }

  figure {
    margin: 0;
  }

  img {
    max-width: 100%;
  }

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
`;

export const TreeContainer = styled.div`
  overflow: hidden;

  ${mobile} {
    border-right: 1px solid currentColor;
  }
`;

export const Tree = styled.div`
  column-gap: 100px;
  column-rule: 1px solid currentColor;

  ${tablet} {
    margin-top: -1px;
    column-count: 2;
  }

  > div {
    padding: 40px 20px 40px 0;
    break-inside: avoid;
    position: relative;

    ${tablet} {
      padding: 40px 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -50px;
      right: -50px;
      height: 1px;
      background-color: currentColor;
    }

    > p:first-child {
      margin-top: 0;
    }

    > p:last-child {
      margin-bottom: 0;
    }
  }
`;
