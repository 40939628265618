import Link from 'next/link';
import React from 'react';

import { CarouselRow, CarouselItem } from 'components/common/carousel';
import Container from 'components/common/container';
import Image from 'components/common/image';
import MaskedIcon from 'components/common/masked-icon';
import { HeadingWrapper } from 'components/common/text';
import { Heading2, HeadingLink } from 'components/common/text';
import { IHeadingLink } from 'components/common/text/heading-link/types';
import { desktop, mobile, tablet } from 'utils/media';
import { SanityImageAsset } from 'utils/types';

import {
  StyledLink,
  StyledResponsiveBox,
  MaskedIconWrapper,
  Label,
  ShortcutGridSection,
} from './styles';

export interface ShortcutProps {
  _key: string;
  _type: 'shortcut';
  name?: string;
  path?: string;
  image?: string;
  icon?: SanityImageAsset;
}

export interface ShortcutGridProps {
  title?: string;
  titleLink?: IHeadingLink;
  shortcuts?: ShortcutProps[];
}

export default function ShortcutGrid({
  title,
  shortcuts = [],
  titleLink = {},
}: ShortcutGridProps) {
  const { text, url } = titleLink;

  return (
    <ShortcutGridSection bg="light">
      <Container noGutter noPadding>
        {title ? (
          <HeadingWrapper>
            <Heading2 inline>{title}</Heading2>
            {text && url && (
              <Link href={url} passHref>
                <HeadingLink href={url}>{text}</HeadingLink>
              </Link>
            )}
          </HeadingWrapper>
        ) : null}
        <CarouselRow>
          {shortcuts.map((shortcut) => (
            <CarouselItem key={shortcut._key}>
              <Link href={shortcut.path || '/'} passHref>
                <StyledLink>
                  <StyledResponsiveBox aspectRatio={3 / 4}>
                    {shortcut.icon ? (
                      <MaskedIconWrapper>
                        <MaskedIcon
                          asset={shortcut.icon}
                          size={{ [mobile]: 48, [tablet]: 48, [desktop]: 64 }}
                        />
                      </MaskedIconWrapper>
                    ) : null}
                    {shortcut.image ? (
                      <Image
                        src={shortcut.image}
                        aspectRatio={3 / 4}
                        sizes={`${mobile} 164px, ${desktop} 600px, ${tablet} 224px`}
                        alt=""
                      />
                    ) : null}
                  </StyledResponsiveBox>
                  <Label>{shortcut.name}</Label>
                </StyledLink>
              </Link>
            </CarouselItem>
          ))}
        </CarouselRow>
      </Container>
    </ShortcutGridSection>
  );
}
