import styled from 'styled-components';

import Section from 'components/common/section';
import { Heading2 } from 'components/common/text';
import { getResponsivePaddingStyles } from 'utils/helpers';
import { tablet } from 'utils/media';
import theme from 'utils/theme';

import { carosuelContainerResponsivePaddingConfig } from './helpers';
import { ICuratorCarouselDropSpotProps } from './types';

interface SocialSectionProps {
  hexCode?: string;
}

export const SocialSection = styled(Section)`
  background-color: ${(props: SocialSectionProps) =>
    props.hexCode ? props.hexCode : ''};

  ${tablet} {
    padding-bottom: 35px;
  }
`;

interface SocialHeading2Props {
  textColor?: Sproutl.ColorScheme;
}

export const SocialHeading2 = styled(Heading2)`
  color: ${(props: SocialHeading2Props) =>
    props.textColor ? theme.colors[props.textColor] : ''};
`;

export const TitleRow = styled.div`
  padding: 0 var(--site-gutter);
  margin-bottom: var(--space-m-l);

  p {
    font-size: var(--step-1);
    margin: var(--space-2xs) 0 0;
  }
`;

export const Description = styled.p`
  margin: 16px 0 40px;
  color: ${(props: SocialHeading2Props) =>
    props.textColor ? theme.colors[props.textColor] : ''};
`;

export const CuratorCarouselDropSpot = styled.div<ICuratorCarouselDropSpotProps>`
  z-index: ${(props) => props.zIndex};
`;

export const CarouselContainer = styled.div`
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    height: 0;
    display: block;
    width: 100%;
    ${getResponsivePaddingStyles(carosuelContainerResponsivePaddingConfig)};
  }

  & > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  & ${CuratorCarouselDropSpot} {
    height: 100%;
  }
`;
